.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  > div {
    flex: 1;
  }
}

.photoUploadWrapper {
  min-height: 240px;
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  // border: 2px dashed #d3d3d3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadInput {
  display: none;
}

.photoButton {
  margin: 10px 0;
  margin-bottom: 20px;
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
}

.photoDescription {
  margin-top: 10px;
  font-size: 14px;
  color: #2F2F33;
  text-align: center;
}

.addImageText {
  color: #AAAABB;
  margin: 0;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  background-color: #F9F9FB;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  // margin-bottom: 16px;

  &.active {
    border-color: #2196f3;
  }

  &.error {
    border-color: #f44336;
  }
}

.dropzoneContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.dropzoneText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  span {
    &:first-child {
      font-weight: 500;
    }
  }
}

.dropzoneSubtext {
  color: #666;
  font-size: 14px;
}

.uploadButton {
  margin-top: 12px;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.previewItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 8px 12px;
  // background-color: #f5f5f5;
  // border-radius: 4px;
}

.fileName {
  // flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 12px;
  word-break: break-all;
  background-color: #F9F9FB;
  border-radius: 10px;
  padding: 12px 16px;
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #f44336;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: darken(#f44336, 10%);
  }
}

.errorMessage {
  color: #C15B5B;
  font-size: 14px;
  margin-top: 8px;
  // background-color: #F9F9FB;
  border-radius: 10px;
  padding: 12px 16px;
  border: 1px solid #C15B5B;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;

  @media (max-width: 768px) {
    align-items: center;
  }
}

.fileItem {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.photoSubtitleWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ulList {
  list-style-position: inside;
  margin-left: 10px;
  font-size: 14px;
}

.fileRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  // gap: 10px;
  // margin-top: 10px;
}

.rowFileWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.deleteButton {
  cursor: pointer;
}

.submitButtonContainer {
  max-width: 430px;
  margin-top: 24px;
}

.successBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background-color: #F9F9FB;
  border-radius: 20px;
  margin-bottom: 16px;

  span {
    color: #4CAF50;
    font-weight: 500;
  }
}

.buttonWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  align-self: center;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 330px;
  // padding: 0 10px;
  
  p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: #2F2F33;
  }
}

.errorModalContent {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  
  p {
    margin: 0;
    color: #333;
    font-size: 14px;
    line-height: 1.5;
  }
}

.modalText {
  text-align: center;
}

.modalButtons {
  display: flex;
  gap: 12px;
  justify-content: center;
}