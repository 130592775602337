.container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}

.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .content {
        flex: 1 0 auto;
    }

    footer {
        flex-shrink: 0;
    }
}