.marginBottom {
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-top: 90px;
  }
}

.date {
  color: #333;
}

.time {
  color: #AAAABB;
  margin-top: 8px;
}

.offerName {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}

.offerCategory {
  font-size: 16px;
  color: #555;
  margin-top: 8px;
}

.settiungsBox {
  width: 42px;
  height: 42px;
  background-color: #F9F9FB;
  border-radius: 10px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-end;
  gap: 10px;

  .searchInput {
    padding: 10px;
    width: 30%;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .showButton,
  .resetButton {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  .showButton {
    background-color: #4CAF50;
    color: white;
  }

  .resetButton {
    background-color: white;
    border: 1px solid #ccc;
  }
}

.moblieFilter {
  margin: 15px 0;
}

.columnVisibility {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    input[type="checkbox"] {
      appearance: none;
      width: 18px;
      height: 18px;
      border: 2px solid #d9d9d9;
      border-radius: 4px;
      margin-right: 8px;
      display: inline-block;
      position: relative;
    }

    input[type="checkbox"]:checked {
      background-color: #fff;
      /* Белый фон, как на картинке */
      border: 2px solid #000;
      /* Черная рамка, как на картинке */
    }

    input[type="checkbox"]:checked::after {
      content: '✔';
      font-size: 12px;
      color: #000;
      position: absolute;
      top: -2px;
      left: 2px;
    }

    &:not(:first-child) {
      padding-left: 32px;
      /* Отступ для всех, кроме первого */
    }
  }

  label input:checked {
    background-color: #000;
    border-color: #000;
  }

  label:last-child {
    margin-bottom: 0;
  }
}

.statusCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusIndicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &.draft {
    background-color: #2196F3;
  }

  &.rejected {
    background-color: #F44336;
  }
}

.offerBtnMobileFilter,
.offerMobileFilter {
  width: 100%;
}

.filterButtons {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}

.marginBottom {
  margin-bottom: 20px;
}

.filters {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .twoFilters {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  .filterButtons {
    margin-left: auto;
    display: flex;
    gap: 10px;
  }

  .settingsBox {
    position: relative;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.columnSettings {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  min-width: 200px;

  .columnOption {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    cursor: pointer;

    &:not(:first-child) {
      padding-left: 28px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    input[type="checkbox"] {
      position: relative;
      width: 16px;
      height: 16px;
      margin: 0;
      appearance: none;
      border: 1px solid #000;
      border-radius: 4px;
      cursor: pointer;

      &:checked {
        // background-color: #000;
        border-color: #000;

        &::after {
          content: '';
          position: absolute;
          left: 4px;
          top: 1px;
          width: 6px;
          height: 10px;
          border: solid black;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }

    label {
      cursor: pointer;
      user-select: none;
      color: #000;
      font-size: 14px;
    }
  }
}