.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.input {
    padding: 12px;
    border: 1px solid #E0E0E0;
    border-radius: 16px;
    font-size: 16px;
    width: 100%;
    height: 64px;

    &:focus {
        outline: none;
        border-color: #71BE81;
    }
}

.description {
    margin-top: 4px;
    color: #666666;
    margin-bottom: 16px;
}

.submitButton {
    width: 100%;
    max-width: 220px;
    padding: 12px 24px;
    background-color: #71BE81;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
        background-color: darken(#71BE81, 5%);
    }
}

.loadingRow {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
}

.messageWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 32px;
}

.approvedWrapper {
    background-color: rgba(185, 218, 192, 0.1);

    span {
        color: #71BE81;
    }
}

.rejectedWrapper {
    background-color: rgba(235, 87, 87, 0.1);

    span {
        color: #EB5757;
    }
}

.statusIcon {
    width: 24px;
    height: 24px;
}

.approveText {
    font-size: 16px;
    color: #333333;

    &.loading {
        color: #666666;
    }
}

.rotating {
    animation: rotate 1s linear infinite;
}

.errorMessage {
    color: red;
}

.titleWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}