.checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 26px;
    height: 26px;
    background-color: #F7F8FA; // цвет фона для неактивного состояния
    border: 3px solid #E0E2EA; // граница вокруг чекбокса
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s ease;
    flex-shrink: 0;

    &:hover {
        border-color: darken(#E0E2EA, 10%);
    }

    &:checked {
        background-color: #FFFFFF;
        border: 3px solid #2F2F33;

        &::after {
            content: '';
            display: block;
            width: 9px;
            height: 10px;
            border: solid #2F2F33;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            margin-left: 5px;
            margin-top: 2px;
        }
    }
}