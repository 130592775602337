.pendingApplicationsList {
    margin-top: 60px;

    .title {
        margin-bottom: 16px;
    }
}

.subTitle {
    color: #888;
    font-size: 12px;
}

.lowHours {
    color: #C15B5B;
    font-weight: bold;
}

.offersList {
    width: 100%;

    @media (max-width: 768px) {
        margin-top: 70px;
    }

    .filters {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .searchInput {
            padding: 10px;
            width: 30%;
            border-radius: 5px;
            border: 1px solid #ccc;
        }

        .showButton,
        .resetButton {
            padding: 10px 20px;
            border-radius: 5px;
            border: none;
            cursor: pointer;
        }

        .showButton {
            background-color: #4CAF50;
            color: white;
        }

        .resetButton {
            background-color: white;
            border: 1px solid #ccc;
        }
    }
}

.filterButtons {
    display: flex;
    gap: 10px;
    align-items: flex-end;

    @media (max-width: 768px) {
        justify-content: space-between;
    }
}

.twoFilters {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.selectWrapper {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.selectCategory {
    width: 100%;
    height: 42px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;

    // Если выбрана опция "Все"
    &.allSelected {
        color: gray; // Меняем цвет текста в селекте на серый
    }
}

.selectCategory option[value=""] {
    color: gray;
}

.offerCell {
    height: 100%;
    display: flex;
    align-items: center; // Добавляем для вертикального центрирования элементов

    img {
        height: 30px; // Устанавливаем фиксированную высоту для логотипа
        object-fit: contain; // Обеспечиваем правильное отображение изображений
    }

    span {
        line-height: 1.2; // Устанавливаем высоту строки, чтобы текст был аккуратным
    }
}

.marginBottom {
    margin-bottom: 24px;
}

.offerCellImage {
    display: flex;
    width: 100px;
    height: 50px;
    margin-right: 10px;
    padding: 10.5px 0px 11.5px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #E2E2ED;
    background: #FFF;
}

.cardsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.cardLogo {
    // align-self: center;
    display: flex;
    width: 100px;
    height: 50px;
    margin-right: 10px;
    padding: 10.5px 0px 11.5px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #E2E2ED;
    background: #FFF;

    img {
        height: 30px; // Устанавливаем фиксированную высоту для логотипа
        object-fit: contain; // Обеспечиваем правильное отображение изображений
    }
}

// .cardContent {
//     text-align: center;
// }

.cardRow {
    width: 100%;
    display: flex;
    gap: 16px;
    // justify-content: space-between;
}

@media (min-width: 768px) {
    .cardsContainer {
        display: none;
    }
}

.filterMenu {
    cursor: pointer;
    margin-bottom: 16px;
}

.clientNameCell {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Modal.module.scss */

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    /* Покрывает всю ширину экрана */
    height: 100vh;
    /* Покрывает всю высоту экрана */
    background-color: white;
    /* Полупрозрачный черный фон */
    display: flex;
    // align-items: center;
    justify-content: center;
    z-index: 1000;
    // margin-top: 70px;
    /* Убедитесь, что модалка поверх других элементов */
}

.modalContent {
    background-color: white;
    padding: 0 20px;
    border-radius: 10px;

    width: 100%;
    /* Ширина контента модалки */
    height: 100%;
    /* Максимальная ширина модального окна */
    z-index: 1001;
    /* Задать больший индекс для контента, чтобы он был над backdrop */
    position: relative;
    /* Для нормального позиционирования внутри модалки */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.arrowLeft {
    cursor: pointer;
}

.modalWrapper {
    width: 100%;
    margin-top: 70px;
}

.lineWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

// В стилях для ячеек таблицы
.tableCellActions {
    text-align: right;
}

.explainedText {
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
    padding: var(--padding-M-Large, 28px) var(--padding-M, 24px);
    align-items: center;
    gap: var(--S-Small, 12px);
    align-self: stretch;
    border-radius: 16px;
    background: rgba(193, 91, 91, 0.05);
}

.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 32px;
    text-align: center;
    
    .errorTitle {
        color: var(--error-color);
    }
    
    .errorMessage {
        color: var(--text-secondary);
        margin-bottom: 8px;
    }
}