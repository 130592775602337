@import "../../../../assets/styles/variables.scss";

.table {
    width: 100%;
    border-collapse: collapse;
    // border-radius: 25px;
    overflow: hidden;
    table-layout: fixed;

    th,
    td {
        padding: 10px;
        text-align: left;
        vertical-align: middle;
    }

    th {
        background-color: #878799;
        color: white;
        height: 50px;
        font-weight: 400;
    }

    tbody tr:nth-child(odd) {
        background-color: white;
    }

    tbody tr:nth-child(even) {
        background-color: #F9F9FB;
    }

    tbody tr:hover td {
        background-color: #f1f1f1;
    }

    // td:last-child {
    //     text-align: right;
    // }

    .actionButton {
        background-color: #4CAF50;
        color: white;
        padding: 8px 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
}

.tableContainer {
    overflow-x: auto; // Добавляет горизонтальную прокрутку для таблицы
    -webkit-overflow-scrolling: touch; // Для плавной прокрутки на мобильных устройствах

    .table {
        width: 100%;
        min-width: 600px; // Устанавливает минимальную ширину таблицы
        border-collapse: collapse;
        overflow: hidden;

        th,
        td {
            padding: 10px;
            text-align: left;
            vertical-align: middle;
        }

        th {
            background-color: #AAAABB;
            color: white;
            height: 50px;
            font-weight: 400;
        }

        tbody tr:nth-child(odd) {
            background-color: white;
        }

        tbody tr:nth-child(even) {
            background-color: #F9F9FB;
        }

        tbody tr:hover td {
            background-color: #f1f1f1;
        }
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    button {
        width: 36px;
        height: 36px;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        background-color: #FFFFFF;
        color: $color-accent;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        // Состояния кнопок
        &.default {
            background-color: #FFFFFF;
            color: $color-accent;
        }

        &:hover {
            background-color: $color-accent;
            color: #FFFFFF;
        }

        &.selected {
            background-color: $color-accent;
            color: #FFFFFF;
        }

        &.disabled {
            background-color: #F5F5F5;
            color: #A0A0A0;
            cursor: not-allowed;
        }
    }

    // Стрелки без фона
    .arrow {
        background-color: transparent;
        border: none;
        color: $color-accent;
        font-size: 20px;
        cursor: pointer;

        // &:hover {
        //     color: $color-accent;
        // }
    }

    .disabledArrow {
        background-color: transparent;
        border: none;
        color: #A0A0A0;
        cursor: not-allowed;
    }
}

.noDataMessage {
    text-align: center;
    margin: 20px 0;
    font-size: 16px;
    color: #a0a0a0;
}

.dateColumn {
    border-right: 2px solid #ccc; // Линия разделения для колонки "Дата"
}

.titleRow {
    border-bottom: 2px solid #ccc;

    th {
        background-color: #878799 !important;
    }

}

.firstColumnSeparator {
    border-right: 2px solid #ccc; // Линия для отделения первого столбца
}

.withSeparator {
    // border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
    /* Разделительная линия после столбца "Оффер" */
}

.titleCell {
    // width: 100%;
    background-color: #878799;
    text-align: left;
    font-weight: bold;
    color: white;
    // display: flex;
    // align-items: center;
    // gap: 8px;
}

.infoIcon {
    margin-left: 5px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }
}

.acceptedIcon {
    margin-right: 10px;
}

.tooltipRow {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }
}

.statusIndicator {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    background: #F9F9FB;
    padding: 4px 8px;
    border-radius: 16px;
}

.statusDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    flex-shrink: 0;
}

.statusApproved {
    background-color: #4CAF50;
}

.statusRejected {
    background-color: #F44336;
}

.statusPending {
    background-color: #2196F3;
}

.tooltipWrapper {
    @media (min-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}