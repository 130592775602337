@import '../../../assets/styles/variables.scss';

.button {
    // width: 100%;
    // height: 64px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500; //550;
    white-space: nowrap;
    // gap: 10px;

    &:disabled {
        background-color: $background-general;
        color: $text-secondary;
        cursor: not-allowed;
        border: 2px solid #cccccc !important;
    }

    &.main {
        background-color: $color-accent;
        color: #fff;
        text-decoration: none;

        &:hover {
            background-color: darken($color-accent, 10%);
        }

        &:active {
            background-color: darken($color-accent, 20%);
        }
    }

    &.main2 {
        background-color: #fff;
        color: $color-general;
        border: 2px solid $color-general;
        text-decoration: none;

        &:hover {
            background-color: #f1f1f8;
            // color: #fff;
        }

        &:active {
            background-color: darken($color-general, 20%);
            color: #fff;
        }
    }

    &.mainGreen {
        background-color: #fff;
        color: $color-accent;
        border: 2px solid $color-accent;
        text-decoration: none;
        // height: 42px;
        // width: 120px;
        font-size: 14px;
        font-weight: 450;

        &:hover {
            background-color: $color-accent;
            color: #fff;
        }

        &:active {
            background-color: darken($color-general, 20%);
            color: #fff;
        }
    }

    &.secondary {
        background-color: $color-accent;
        color: #fff;
        // border-radius: 10px;
        height: 42px;
        font-size: 14px;
        font-weight: 450;

        &:hover {
            background-color: darken($color-accent, 10%);
        }

        &:active {
            background-color: darken($color-accent, 20%);
        }
    }

    &.secondary2 {
        background-color: $color-general;
        color: #fff;
        border-radius: 10px;
        height: 42px;
        font-size: 14px;
        font-weight: 450;

        &:hover {
            background-color: darken($color-general, 10%);
        }

        &:active {
            background-color: darken($color-general, 20%);
        }

        &.white {
            background-color: #fff;
            color: $color-general;
            border: 2px solid #ccc;

            &:hover {
                background-color: $color-general;
                color: #fff;
            }

            &:active {
                background-color: darken($color-general, 20%);
                color: #fff;
            }
        }
    }

    &.link {
        background: none;
        color: $color-accent;
        text-decoration: underline;
        height: 33px;
        font-size: 14px;
        font-weight: 550;

        &:hover {
            color: darken($color-accent, 10%);
        }

        &:active {
            color: darken($color-accent, 20%);
        }
    }

    &.linkDark {
        background: none;
        color: $color-general;
        text-decoration: underline;
        height: 33px;
        font-size: 14px;
        font-weight: 550;

        &:hover {
            color: darken($color-general, 10%);
        }

        &:active {
            color: darken($color-general, 20%);
        }
    }

    &.small {
        height: 33px;
        padding: 4px 8px;
        background-color: #fff;
        color: $color-general;
        border: 2px solid #ccc;
        font-size: 14px;

        &:hover {
            background-color: $color-general;
            color: #fff;
        }

        &:active {
            background-color: darken($color-general, 20%);
            color: #fff;
        }
    }

    &.small2 {
        height: 33px;
        padding: 4px 8px;
        background-color: $color-accent;
        color: #fff;
        border-radius: 20px;
        font-size: 14px;

        &:hover {
            background-color: darken($color-accent, 10%);
        }

        &:active {
            background-color: darken($color-accent, 20%);
        }
    }
}

.disabled {
    background-color: $background-general !important;
    color: $text-secondary !important;
    cursor: not-allowed;
}

.fullWidth {
    width: 100%;
}

.iconLeft {
    margin-right: 12px;
}

.iconRight {
    margin-left: 12px;
}

// .children {
//     padding-left: 12px;
// }