.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    button {
        padding: 10px 20px;
        margin: 0 10px;
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;

        &.active {
            color: #4caf50;
            font-weight: bold;
        }
    }
}

.description {
    margin-bottom: 20px;
    text-align: left;
    color: #999;
}

.section {
    margin-bottom: 20px;
    text-align: start;
    width: 100%;

    @media (max-width: 768px) {
        width: 100%;
    }

    h2 {
        font-size: 18px;
        margin-bottom: 10px;
        text-align: start;
    }

    .banks,
    .products {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 12px;

        @media (max-width: 768px) {
            justify-content: center;

        }

        .bankOption,
        .productOption {
            margin-right: 15px;
            display: flex;
            align-items: center;

            input {
                margin-right: 10px;
            }

            &.selected {
                font-weight: bold;
                color: #4caf50;
            }
        }
    }
}

.submitButton {
    padding: 10px 30px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
}