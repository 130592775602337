.routerContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  background-color: #f9f9fb;
  height: 60px;
  align-items: center;
  // padding-left: 30px;
  // padding-right: 30px;
  margin-bottom: 30px;
  border-top: 1px solid rgba(226, 226, 237, 1);
}

.fullWidth {
  width: 100%;
  display: flex;
  justify-content: space-between;

  p {
    cursor: pointer;
  }
}

.routerRightBox {
  display: flex;
  align-items: center;
  gap: 30px;
  color: rgba(170, 170, 187, 1);
  font-size: 14px;
  font-weight: 550;
  text-transform: uppercase;
}

.routerLeftBox {
  display: flex;
  gap: 10px;
  color: rgba(170, 170, 187, 1);
  font-size: 14px;
  font-weight: 550;
  text-transform: uppercase;
  align-items: center;
  margin-left: 15px;
}

.activeRoute {
  color: #71BE81;
}

.routerRightBox p:hover,
.routerLeftBox:hover {
  color: #71BE81;
  /* Зелёный цвет на hover */
}

@media (max-width: 768px) {
  .routerContainer {
    display: none;
  }
}