.step1 {
    width: 100%;
    // max-width: 550px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
}

.checkboxWrapper {
    margin-top: 44px;
    padding-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-top: solid 1px #E2E2ED;

}