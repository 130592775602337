.wrapper {
    width: 100%;
    max-width: 566px;
    padding: 20px;
    background: #fff;
    border-radius: 20px;
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 20px;
    flex-direction: column;
    border: 1px solid #E2E2ED;
}

h2 {
    margin-bottom: 20px;
    text-align: center;
}

.note {
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
}

.genderWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.buttonWidth {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 566px;
    padding: 20px;
    background: #fff;
    border-radius: 20px;
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 20px;
    flex-direction: column;
    border: 1px solid #E2E2ED;
}

.formWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
        &>div:last-child {
            margin-right: auto;
            margin-left: 0;
            justify-content: flex-start;
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        gap: 24px;
        align-items: center;

        &>div:last-child {
            margin: 0 auto;
            justify-content: center;
        }
    }
}

.flexRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    // @media (max-width: 768px) {
    //     grid-template-columns: 1fr;
    // }
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
}

.radioGroup {
    display: flex;
    gap: 20px;
    align-items: center;
}