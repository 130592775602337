/* src/shared/layouts/AuthLayout/AuthLayout.module.scss */
@import "../../../assets/styles/variables.scss";

.wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    background-color: $color-general-2;
}


.formWrapper {
    width: 100%;
    max-width: 400px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leftSide {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding: 0 15px;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.rightSide {
    width: 40%;
    background-color: #8DDA9D;
    background-image: url('../../../assets/images/register-right-side-back.png');
    background-size: cover;
    background-position: center;

    @media (max-width: $breakpoint-mobile) {
        display: none;
    }
}