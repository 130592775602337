@import '../../../../assets/styles/variables.scss';

.inputWrapper {
    position: relative;
    width: 100%;
}

.errorMessage {
    color: $color-error;
    font-size: 12px;
    margin-top: 4px;
    display: block;
}

.reactSelect {
    :global {
        .select__control {
            min-height: 64px !important;
            border: 1px solid $color-secondary;
            border-radius: $input-border-radius;
            background: $background-general;
            cursor: pointer;
            box-shadow: none;

            &--is-focused {
                border-color: $color-accent;
                box-shadow: none;
            }

            &:hover {
                border-color: $color-accent;
            }
        }

        .select__value-container {
            padding: 10px 16px;
        }

        .select__indicator-separator {
            display: none;
        }

        .select__dropdown-indicator {
            padding-right: 16px;
            color: $color-secondary;
        }

        .select__menu {
            margin-top: 4px;
            border-radius: $input-border-radius;
            z-index: 100;
        }

        .select__option {
            height: 41px;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: $color-general;

            // &--is-focused {
            //     background-color: $color-accent-light;
            // }

            &--is-selected {
                background-color: white;
                // color: white;
                font-weight: bold;
            }
        }

        .select__placeholder {
            color: #AAAABB;
            font-size: 14px;
        }

        .select__single-value {
            color: $color-general;
            font-size: 14px;
        }
    }

    &.error {
        :global {
            .select__control {
                border-color: $color-error;
            }
        }
    }
}