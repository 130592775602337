.wrapper {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.checkboxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}