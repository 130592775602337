$breakpoint-mobile: 320px;
$breakpoint-smartphone: 576px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;
$breakpoint-large-laptop: 1440px;
$breakpoint-desktop: 1920px;
$breakpoint-large-desktop: 1920px;

// Переменные для input
$input-padding-top: 12px;
$input-padding-bottom: 12px;
$input-padding-left: 16px;
$input-padding-right: 16px;
$input-margin-right: 12px;
$input-icon-spacing: 12px;
$input-border-radius: 15px;

$color-general: #2F2F33;
$color-general-2: #FFFFFF;
$color-secondary: #AAAABB;
$color-accent: #71BE81;
$color-error: #C15B5B;

$background-general: #F9F9FB;
$background-secondary: #878799;
$text-secondary: #AAAABB;
$text-link: #427ED8;

$linkColor: #0052b4;

$breakpoint-mobile: 768px;