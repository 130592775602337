@import "../../../assets/styles/mixins.scss";

.recoveryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.recoverySection {
    width: 100%;
    max-width: 450px;
    display: flex;
    gap: 28px;
    flex-direction: column;
    align-items: center;
}

.recoveryContent {
    width: 100%;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 44px;
}

// .recovery-main {
//     position: relative;
//     max-width: 584px;
//     height: 100%;
//     background-color: white;
//     border-radius: 8px;
//     padding: 30px 60px 40px 60px;

//     @media (max-width: 768px) {
//         padding: 0 20px 40px 20px;

//         img {
//             width: 100%;
//             max-width: 288px;
//         }
//     }
// }

// .approve-recovery-main {
//     max-width: 584px;
//     height: 100%;
//     background-color: white;
//     border-radius: 8px;
//     padding: 30px 60px 40px 60px;

//     &__wrong-code {
//         color: #d80027;
//         font-size: 13px;
//     }

//     &__send-again {
//         display: flex;
//         justify-content: center;
//         color: #6c6ea0;
//         font-size: 13px;
//         font-weight: 600;
//     }

//     @media (max-width: 768px) {
//         padding: 0 20px 0 20px;

//         img {
//             width: 100%;
//             max-width: 288px;
//         }
//     }
// }

// .recovery-title {
//     @include text($size: 28px, $weight: 600, $text-align: center, $line: auto);
// }

// .recovery-container {
//     position: relative;
//     width: 100%;
//     min-height: 80vh;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

//     &--justify-start {
//         justify-content: start;
//     }

//     &--full-height {
//         height: 100%;
//     }

//     &--padding-top {
//         padding-top: 95px;
//     }

//     &--padding-top-none {
//         padding-top: 0;
//     }

//     &--padding-bottom {
//         padding-bottom: 30px;
//     }
// }

// .recovery-footer {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     position: absolute;
//     bottom: 0;
//     left: 0;

//     &--static {
//         position: static;
//     }

//     &--desktop-static {
//         position: absolute;

//         @media (max-width: 768px) {
//             position: static;
//         }
//     }
// }

// .recovery-btn {
//     @include button($width: 133px, $height: 42px, $bg: #ff1053);
//     @include text($size: 16px, $weight: 400, $color: white, $text-align: center);
//     margin-top: 10px;
// }

// .recovery-btn-box {
//     width: 100%;
//     display: flex;
//     justify-content: center;
// }

// .recovery-input {
//     width: 100%;
// }

// .recovery-main-body {
//     @include text($size: 20px, $weight: 400, $text-align: left);

//     @media (max-width: 768px) {
//         font-size: 14px;
//     }
// }

// .check-password {
//     height: 100%;
// }

// .recovery-form-btn {
//     width: 102px;
//     height: 42px;
//     background-color: #ff1053;
//     color: white;
//     border-radius: 8px;
//     outline: none;
//     border: none;
//     margin-top: 20px;
// }

// .recovery-form {
//     margin-bottom: 30px;
// }

// .recovery-password-done {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     max-width: 520px;
// }

// .recovery-form-error {
//     width: 100%;
// }

// .recovery-btn:disabled {
//     opacity: 0.5;
//     cursor: not-allowed;
// }

// .recovery-form-btn:disabled {
//     opacity: 0.5;
//     cursor: not-allowed;
// }