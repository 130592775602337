.formWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.flexRow {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 0;
}

.title {
    width: unset;
}

.titleWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

// .tooltipWrapper {
//     max-width: 200px;
// }

.photoSubtitleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .success {
        color: green;
        font-size: 14px;
    }
}

// .uploadSection {
//     margin-top: 20px;
// }

.header {
    margin-bottom: 16px;
    font-weight: 500;
}

.dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 240px;
    border-radius: 20px;
    padding: 24px;
    text-align: center;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
    margin-bottom: 20px;

    &:hover:not(.disabled) {
        border-color: #007AFF;
        background-color: rgba(0, 122, 255, 0.05);
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
        background-color: #f0f0f0;
        border-color: #ddd;
    }

    &.active {
        border-color: #007AFF;
        background-color: rgba(0, 122, 255, 0.1);
    }

    &.dragActive {
        border-color: #2196f3;
        background-color: rgba(33, 150, 243, 0.1);
    }
}

.uploadContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.addImageText {
    color: #666;
    margin: 0;
    font-size: 14px;
}

.fileList {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}

.fileInfo {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 12px;
    border-radius: 8px;
    gap: 8px;
}

.fileName {
    font-weight: 500;
}

.fileSize {
    color: #666;
    font-size: 12px;
}

.deleteButton {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;

    &:hover:not(:disabled) {
        background-color: #eee;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.error {
    color: #d32f2f;
    // margin-top: 8px;
    font-size: 14px;
    border: 1px solid #d32f2f;
    border-radius: 16px;
    padding: 8px;
}

.success {
    color: #4caf50;
    // margin-top: 8px;
    font-size: 14px;
}

.statusMessage {
    // margin-top: 16px;
    padding: 15px 8px;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 12px;

    &.success {
        color: #4caf50;
    }

    &.error {
        color: #f44336;
    }
}

.loader {
    width: 16px;
    height: 16px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #1976d2;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.filesWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.uploadedFilesTitle {
    margin-top: 28px;
    ;
}

.submitButton {
    margin-top: 10px;
}

.successBlock {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    background-color: rgba(76, 175, 80, 0.1); // Еле заметный зеленый фон
    border-radius: 16px;
}

.uploadButton {
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .content {
        padding: 0px;
    }

    .dropzone {
        padding: 16px;
    }

    .fileItem {
        padding: 10px;
    }
}