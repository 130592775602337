.wrapper {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
}

.toRegister {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.toForgot {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    div {
        margin-top: -30px;
    }
}