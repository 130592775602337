@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
}

.loaderSmall {
    width: 16px;
    height: 16px;
    border-width: 2px;
}

.loaderLarge {
    width: 40px;
    height: 40px;
    border-width: 4px;
}

.loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #666;
    font-size: 14px;
}

.loaderDark {
    border-top-color: #2c3e50;
}

.loaderLight {
    border-top-color: #ecf0f1;
}