.section {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 20px;
}

.pageWrapper {
    background-color: lightgray;
    padding: 15px;
}

.sectionTitle {
    padding-bottom: 20px;
}