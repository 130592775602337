.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 0;
}

.successMessage {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #4CAF50;
}

.errorMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 5px;
    margin-bottom: 10px;
    background-color: #ffebee;
    border: 1px solid #ef5350;
    border-radius: 4px;
    color: #d32f2f;
    width: 100%;
    max-width: 400px;
}

.successIcon {
    width: 24px;
    height: 24px;
}