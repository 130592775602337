.partnerDetails {
    width: 100%;
    // max-width: 566px;
    background-color: #f5f7fa;
    padding: 20px;
    border-radius: 10px;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .details {
        p {
            margin: 10px 0;
            font-size: 14px;

            strong {
                font-weight: 600;
                margin-right: 10px;
            }
        }
    }

    .detailsElements {
        display: flex;
        justify-content: space-between;
    }

    .ProfileFormProfileForm {
        font-size: 15px;
        font-weight: 450;
        color: rgba(170, 170, 187, 1);
    }

    .mobileArrow {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .upIcon {
        display: none;
    }



    @media (max-width: 768px) {
        .upIcon {
            display: block;
        }
    }


}