.selectWrapper {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 768px) {
        max-width: unset;
    }
}

.not-mobile-category {
    @media (max-width: 768px) {
        display: none;
    } 
}

// .selectLabel {
//     font-size: 14px;
//     color: #333;
// }

.selectCategory {
    width: 100%;
    height: 42px;
    padding: 10px 30px 10px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
    appearance: none; 
    padding-right: 40px; 
    background-image: url('../../../assets/icons/select-arrow-icon.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px; 

    &.allSelected {
        color: gray;
    }
}

.selectCategory option[value=""] {
    color: gray;
}