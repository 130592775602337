.sliderContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    // text-align: center;
}

.requestedAmount {
    display: flex;
    flex-wrap: wrap; // Позволяет тексту и значению переноситься на следующую строку
    gap: 10px;
    align-items: center;
    font-size: 16px;
    width: 100%;
}

.value {
    font-size: 20px;
    background-color: #f0f0f0;
    padding: 6px 12px;
    border-radius: 17px;
    // margin-top: 4px;
}

.slider {
    width: 100%;
    margin-bottom: 20px;
    -webkit-appearance: none;
    appearance: none;
    height: 8px;
    background: #e5e5e5;
    border-radius: 5px;
    outline: none;
    transition: background 0.2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    background: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
}

.markers {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #AAAABB;
}