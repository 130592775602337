.calendarSelectContainer {
    position: relative;
    display: inline-block;
    font-family: 'Inter', sans-serif;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.datePicker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    height: 42px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.datePicker span {
    font-size: 14px;
}

.icon {
    margin-left: 10px;
}

.calendarDropdown {
    position: absolute;
    // top: 50px;
    z-index: 10;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .react-calendar {
        border: unset !important;
        width: 100%;
    }

    .react-calendar__tile--range {
        background: #E5F4E3 !important;
        color: #4CAF50 !important;
    }

    .react-calendar__tile--rangeStart,
    .react-calendar__tile--rangeEnd {
        background: #4CAF50 !important;
        color: #fff !important;
        border-radius: 50%;
    }

    .react-calendar__tile--now {
        background: #F5F5F5 !important;
        color: #000 !important;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}


.calendarActions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    // padding: 0 20px;
}

.resetButton,
.confirmButton {
    // width: 100px;
    padding: 8px 10px;
    border-radius: 15px;
    border: none;
    font-size: 14px;
    cursor: pointer;
}

.resetButton {
    background-color: white;
    // color: #4CAF50;
    border: 1px solid black;
}

.confirmButton {
    background-color: #71BE81;
    color: white;
    width: 50px;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

:global(.react-calendar) {
    border: unset !important;
}

:global(.react-calendar--doubleView) {
    max-width: 600px;
}

:global(.react-calendar__tile--active) {
    background-color: #F7F7F9;
    color: black;
    // border-radius: 10px;
}

:global(.react-calendar__tile--hasActive) {
    // border: 1px solid green !important;
    background-color: unset;
}

:global(.react-calendar__year-view__months) {
    @media (max-width: 768px) {
        gap: 20px;
    }
}

    :global(.react-calendar__year-view__months) {
        gap: 20px;

        button {
            /* Дополнительные стили для кнопки при ширине до 768px */
            overflow: visible !important;
        }
    }


:global(.react-calendar) {
    // width: 580px;
    /* Настраиваем ширину */
    background: white;
    border: unset !important;
    font-family: 'Inter', sans-serif;
    line-height: 1.125em;
}

:global(.react-calendar__month-view__days) {
    width: 280px;
}

:global(.react-calendar__month-view >  div > div) {
    // flex-grow: unset;
    // width: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.highlightedStartDate {
    color: #AAAABB;
}

@media (max-width: 768px) {
    :global(.react-calendar) {
        width: 300px;
    }
}

:global(.react-calendar__navigation button) {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    // color: #E2E2ED;
}

:global(.react-calendar__navigation button:enabled:hover),
:global(.react-calendar__navigation button:enabled:focus) {
    background-color: #e6e6e6;
}

:global(.react-calendar__tile--now) {
    background-color: #ffff76 !important;
    /* Фон для сегодняшней даты */
}

:global(.react-calendar__tile) {
    width: 40px !important;
    /* Фиксированная ширина для квадратных дат */
    height: 40px !important;
    /* Фиксированная высота для квадратных дат */
    padding: 0;
    /* Убираем padding */
    border-radius: 0 !important;
    /* Убираем закругление */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* Убираем лишнее содержимое */
    flex: unset !important;
    /* Убираем автоматическое назначение flex */
    margin-inline-end: unset !important;
    /* Убираем отступы */
}

:global(.react-calendar__tile:enabled:hover),
:global(.react-calendar__tile:enabled:focus) {
    background-color: #e6e6e6;
}

:global(.react-calendar__tile--rangeStart::after),
:global(.react-calendar__tile--rangeEnd::after) {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border: 2px solid #4CAF50;
    /* Обводка кружком */
    border-radius: 50%;
}

:global(.react-calendar__month-view__weekdays__weekday) {
    width: 40px !important;
    height: 40px !important;
    padding: 0;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    flex: unset !important;
    margin-inline-end: unset !important;
}

:global(.react-calendar__month-view__weekdays__weekday--weekend) {
    color: #d10000;
    /* Цвет для выходных */
}

:global(.react-calendar__month-view__weekdays__weekday abbr) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    color: #AAAABB;
}

.presets {
    display: flex;
    gap: 3px;

    button {
        background-color: #F9F9FB;
        border: none;
        color: gray;
        border-radius: 15px;
        padding: 4px 8px;
        font-size: 14px;
        white-space: nowrap;
        cursor: pointer;
        transition: background-color 0.2s ease, color 0.2s ease;

        &:hover {
            background-color: #f0f0f0;
        }
    }

    .activePreset {
        color: black; /* Белый текст */
        border: 2px solid #71BE81; /* Тёмно-зелёная рамка */
    }

    @media (max-width: 768px) {
        flex-wrap: wrap; /* Кнопки переносятся на новую строку */
        gap: 8px; /* Увеличиваем промежуток между кнопками */
    }
}
