.settingsPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }

    @media (max-width: 768px) {
        align-items: center;
    }

    .forms {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        // flex: 1;
    }
}

.partnerDetails {
    width: 100%;
    max-width: 566px;
    align-self: flex-start;
    margin-left: auto;

    @media (max-width: 768px) {
        order: -1;
        align-self: unset;
        margin-left: unset;
    }
}

.marginBottom {
    margin-bottom: 24px;

    @media (max-width: 768px) {
        margin-top: 80px;
    }
}