.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
}

.not-found-btn {
  width: 330px;
  position: absolute;
  margin-top: 300px;

  div {
    font-weight: 550;
    font-size: 15px;
  }
}

.NotFounded {
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 200px;
}

.four-zero-four {
  top: 10px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  margin-top: -700px;
  position: absolute;
}

.logo span {
  color: #56b256;
}


.error-found-img {
  position: absolute;
  margin-top: 30px;
}

.back-icon {

  @media (max-width: 768px) {
    width: 1100px;
  }

  @media (max-width: 450px) {
    width: 850px;
    margin-top: -250px;
  }
}