.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background-color: white;
    padding: 0 20px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    z-index: 1001;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.arrowLeft {
    cursor: pointer;
}

.modalWrapper {
    width: 100%;
    margin-top: 70px;
    padding: 0 20px;
}

.lineWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.filterButtons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

.filterContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    font-size: 26px;
    font-weight: 550;
}