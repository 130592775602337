.cardWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        margin: 0;
    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    border: 1px solid #E2E2ED;
    border-radius: 20px;
    width: 100%;
    max-width: 501px;
    height: 100%;
}

.title {
    margin-top: 20px;
    margin-bottom: 8px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    // padding: 24px;

    .label {
        font-size: 18px;
        font-weight: 600;
        color: #333;
    }

    .amount {
        font-size: 22px;
    }

    .subLabel {
        font-size: 16px;
        margin-top: 8px;
    }
}

.amountInfo {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;

    .label {
        font-size: 22px;
        color: #2F2F33;
    }

    .amount {
        font-size: 22px;
        font-weight: 600;
        color: #2F2F33;
    }
}

.subLabel {
    font-size: 16px;
}

.loadingRow {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.messageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 16px;
    border-radius: 8px;

    &.approvedWrapper {
        background-color: #e8f5e9;
    }

    &.rejectedWrapper {
        background-color: #ffebee;
    }
}

.statusIcon {
    width: 24px;
    height: 24px;

    &.rotating {
        animation: rotate 1s linear infinite;
    }
}

.approveText {
    font-size: 16px;

    &.loading {
        color: #666;
    }
}

.submitButton {
    margin-top: 16px;
    padding: 12px 24px;
    background-color: #007AFF;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #0056b3;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 20px;

    .loadingText {
        color: #666;
        font-size: 14px;
        text-align: center;
    }
}

.resultContainer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 16px;
    background: #F8F9FA;
    border-radius: 8px;
    margin: 8px 0;

    .statusIcon {
        width: 24px;
        height: 24px;
    }

    .messageContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .message {
            font-size: 14px;
            color: #333;
        }

        .hint {
            font-size: 12px;
            color: #666;
        }
    }
}

.retryButton {
    margin-top: 16px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}