@import "../../../assets/styles/variables.scss";

.sliderContainer {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 40px;
  // padding: 5px;
  width: fit-content;
  cursor: pointer;
  margin-right: 10px;
}

.option {
  height: 100%;
  padding: 10px 20px;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #a8a8a8;
  transition:
    background-color 0.3s,
    color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .option:first-child {
//   margin-right: 10px;
// }

.active {
  background-color: $color-accent;
  color: white;
}

.loading {
  opacity: 0.7;
  pointer-events: none;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 0.8s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}