@import "../../assets/styles/mixins.scss";

.approveRegistration {

    @include text($size: 20px, $weight: 400, $text-align: left, $line: auto);

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.approveRegistrationBoxContainer {
    width: 100%;
    display: flex;
    gap: 28px;
    flex-direction: column;
    align-items: center;
}

.approveRegistrationBox {
    max-width: 628px;
    min-height: 353px;
    @include flex($gap: 20px, $direction: column, $justify: start);
    margin-bottom: 30px;

    @media (max-width: 768px) {
        height: 328px;
    }

    &Container {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &AbsoluteLayer {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            z-index: 100;
            background-color: rgba(255, 255, 255, 0.5);
            top: 0;
            left: 0;
            backdrop-filter: blur(5px);
            /* Adds the blur effect */
        }
    }
}

.approveRegistrationInput {
    width: 62px;
    height: 64px;
    background-color: #F9F9FB;
    border-radius: 15px;
    border: 1px solid #E2E2ED;
    outline: none;
    @include text($size: 16px, $weight: 500, $text-align: center);
    transition: border-color 0.3s ease, color 0.3s ease;

    &.error {
        // border-color: $color-error; // красная обводка при ошибке
        border: 1px solid $color-error;
        color: $color-error; // красный цвет текста при ошибке
    }

    @media (max-width: 768px) {
        width: 48px;
        height: 52px;
    }
}

.approveRegistrationInputSection {
    display: flex;
    width: 100%;
    max-width: 412px;
    justify-content: space-between;
}

.approveRegistrationTitle {
    text-align: left;
    margin: 0;
}

.repeatCode {
    cursor: pointer;
}