// ChangePasswordForm.module.scss
.wrapper {
    position: relative;
    width: 100%;
    max-width: 566px;
    // margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 20px;
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 30px;
    flex-direction: column;
    border: 1px solid #E2E2ED;
    // margin-top: 20px;
}

h2 {
    margin-bottom: 20px;
    text-align: center;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.buttonWidth {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
}

.settingsSaveBox {
    background-color: #F9F9FB;
    width: 298px;
    height: 42px;
    border-radius: 50px;
    border: none;
    color: #AAAABB;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.profileSaveBtn {
    height: 42px;
    // margin-top: 20px;
}