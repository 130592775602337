.step7 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.loadingRow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    background-color: #f5f5f5;
}

.messageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    text-align: center;
    width: 100%;

    &.loadingWrapper {
        background-color: #f5f5f5;
    }

    &.approvedWrapper {
        background-color: #e6ffe6;
    }

    &.rejectedWrapper {
        background-color: #ffe6e6;
    }
}

.statusIcon {
    width: 48px;
    height: 48px;

    &.rotating {
        animation: rotate 2s linear infinite;
    }
}

.approveText {
    font-size: 18px;
    font-weight: 500;

    &.loading {
        color: #666;
    }

    &.approved {
        color: #00b300;
    }

    &.rejected {
        color: #ff0000;
    }
}

.submitButton {
    padding: 12px 24px;
    background-color: #71BE81;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}