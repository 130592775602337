.container {
  // display: flex;
  // justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0 24px;
  margin: 0 auto;

  @media(max-width: 768px) {
    padding: 0 15px;
  }
}

.MainContainer {
  flex: 1; // Добавить в стили MainContainer
}