@import '../../../../assets/styles/variables.scss';

.h1 {
    font-size: 26px;
    font-weight: 550;
    line-height: 31.5px;
    letter-spacing: 0;
}

.h2 {
    font-size: 22px;
    font-weight: 500; // medium
    line-height: 26.6px;
    letter-spacing: 0;
}

.bodyL {
    font-size: 16px;
    font-weight: 400; // regular
    line-height: 150%;
    letter-spacing: 0;
}

.bodyMtitle {
    font-size: 15px;
    font-weight: 450;
    line-height: 18px;
    letter-spacing: 0;
}

.bodyMsemiB {
    font-size: 15px;
    font-weight: 600; // semi-bold
    line-height: 18.5px;
    letter-spacing: 0;
}

.bodyM {
    font-size: 15px;
    font-weight: 400; // regular
    line-height: 150%;
    letter-spacing: 0;
}

.bodyS {
    font-size: 14px;
    font-weight: 400; // regular
    line-height: 17px;
    letter-spacing: 0;
}

.bodySsemiB {
    font-size: 14px;
    font-weight: 600; // semi-bold
    line-height: 17px;
    letter-spacing: 0;
}

.bodyXS {
    font-size: 11px;
    font-weight: 550;
    line-height: 13px;
    letter-spacing: 0;
    text-transform: uppercase;
}

/* Добавлены новые стили для меню, кнопок и ввода */
.menuMain {
    font-size: 14px;
    font-weight: 550;
    line-height: 17px;
    text-transform: uppercase;
    color: #AAAABB;
}

.menuFooter {
    font-size: 14px;
    font-weight: 450;
    line-height: 17px;
    text-transform: uppercase;
}

.buttonMain {
    font-size: 15px;
    font-weight: 550;
    line-height: 18.2px;
    text-transform: uppercase;
}

.buttonSecondary {
    font-size: 14px;
    font-weight: 450;
    line-height: 16.9px;
    text-transform: uppercase;
}

.buttonLink {
    font-size: 14px;
    font-weight: 550;
    line-height: 16.9px;
    text-transform: uppercase;
    text-decoration: underline;
}

.inputText {
    font-size: 14px;
    font-weight: 550;
    line-height: 16.9px;
    text-transform: uppercase;
}

/* Добавляем стили для цветов */
.general {
    color: $color-general;
}

.general2 {
    color: $color-general-2;
}

.secondary {
    color: $color-secondary;
}

.accent {
    color: $color-accent;
}

.error {
    color: $color-error;
}

/* Добавляем стили для выравнивания текста */
.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.fullWidth {
    width: 100%;
}