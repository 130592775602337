.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    gap: 20px;
    background: white;
    border-radius: 20px;
    max-width: 500px;
    min-width: 300px;
    // max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    margin: 0 10px;
    box-sizing: border-box;
}

.modalHeader {
    padding: 20px;
    border-bottom: 1px solid #eee;
    font-weight: bold;
    font-size: 18px;
}

.modalBody {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    // flex-direction: column;
    // gap: 20px;
}