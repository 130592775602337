.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 566px;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 20px;
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    gap: 20px;
    border: 1px solid #E0E0E0;
    justify-content: flex-start;

    &.noBorder {
        border: none;
        padding: 0;
    }

    &.minWidth512 {
        min-width: 512px;
    }

    // @media (max-width: 768px) {
    //     padding: 24px 0;
    // }
}

.title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
    color: #333;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}