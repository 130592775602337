.radioLabel {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: #000;

    .radioInput {
        display: none;
    }

    .customRadio {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #ccc;
        position: relative;
        display: inline-block;
    }

    .radioInput:checked+.customRadio {
        background-color: #4caf50;
        border-color: #4caf50;
    }

    .radioInput:checked+.customRadio::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }
}