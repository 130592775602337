.footerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  background-color: #f9f9fb;
  // padding-left: 20px;
  // padding-right: 20px;
  margin-top: 90px;

  @media (max-width: 768px) {
    height: 190px;
  }

}

.fullWidth {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.footerLogoBox {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(47, 47, 51, 1);
  font-size: 14px;
  font-weight: 400;
}


.footerRightBox {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  color: rgba(170, 170, 187, 1);
  font-size: 14px;
  font-weight: 550;
  text-transform: uppercase;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  p {
    cursor: pointer;
  }
}

.yearFooter {

  @media (max-width: 768px) {
    margin-top: 10px;
  }
}

.activeRoute {
  color: #71BE81;
  cursor: auto !important;
}

.footerLeftSideBox {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
}