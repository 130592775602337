.buttonBlock {
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 20px 0;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: solid 1px #E2E2ED;
    margin-top: 44px;

    button:last-child:not(:only-child) {
        margin-left: auto;
    }

    @media (max-width: 768px) {
        justify-content: center;
    }

    :global(.css-0) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.buttonBlockStep1 {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    width: 100%;
    margin: 0 auto;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
    }
}

.prevButton {
    background-color: #ccc;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-right: 20px;
    cursor: pointer;
}

.nextButton {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;

    &:disabled {
        background-color: lightgray;
        cursor: not-allowed;
    }
}

.oneButtonCenter {
    justify-content: center;
}

.twoButtonBlock {
    display: flex;
    gap: 20px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.cancelBlock {
    max-width: 300px;

    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: unset;
    }
}