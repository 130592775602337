.container {
    padding: 20px;
    max-width: 800px;
    border-radius: 8px;
    background-color: #f9f9f9;

    @media (max-width: 768px) {
        margin-top: 70px;
    }
}

.logo {
    display: block;
    max-width: 150px;
    border-radius: 8px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
}

.description {
    font-size: 18px;
    text-align: center;
    color: #555;
}

.details {
    font-size: 16px;
    color: #444;

    p {
        margin: 10px 0;
    }

    a {
        color: #007bff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.notFound {
    text-align: center;
    font-size: 18px;
    color: #e74c3c;
    margin-top: 50px;
}

.offerCellImage {
    display: flex;
    width: 120px;
    height: 60px;
    margin-right: 10px;
    padding: 10.5px 0px 11.5px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #E2E2ED;
    background: #FFF;
}

.titleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.detailsRow {
    margin-top: 24px;
}

.button {
    margin-top: 40px;
}