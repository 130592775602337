.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 30px;
    margin: 0 10px;
    border-radius: 20px;
    max-width: 330px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.errorIcon {
    margin-bottom: 20px;
}

.message {
    font-size: 16px;
    margin: 10px 0 16px 0;
    color: #333;
}

.okButton {
    padding: 10px 20px;
    border: 1px solid #333;
    background-color: white;
    color: #333;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
}

.okButton:hover {
    background-color: #f0f0f0;
}