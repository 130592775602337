.step3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
}

.column {
    flex: 1;
    // background-color: #f9f9f9;
    padding: 20px;
    border-radius: 12px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    gap: 10px;
}

h2 {
    font-size: 18px;
    margin-bottom: 20px;
}

input {
    width: 100%;
}

.columnWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: self-end;
    gap: 24px;

    @media (max-width: 768px) {
        align-items: center;
    }
}