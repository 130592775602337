.step9 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.stepWrapper {
    display: flex;
    min-width: 384px;
    min-height: 244px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #F9F9FB;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 20px;
}

.textCenter {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;

    &.success {
        color: #4CAF50;
    }

    &.error {
        color: #757575;
    }
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.content {
    display: flex;
    width: 100%;
    max-width: 384px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background-color: #F9F9FB;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 20px;
}

.message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    line-height: 24px;

    &.success {
        color: #4CAF50;
    }

    &.error {
        color: #FF0000;
    }
}

.statusMessageText {
    color: black;
}

.error {
    span {
        color: #FF0000;
    }
}