@import '../../../assets/styles/variables.scss';

.recoveryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.recoverySection {
    width: 100%;
    max-width: 450px;
    display: flex;
    gap: 28px;
    flex-direction: column;
    align-items: center;
}

.recoveryContent {
    width: 100%;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 44px;
}

.errorRecoveryPass {
    // font-size: ;
    color: $color-error;
}