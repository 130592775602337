@import 'assets/styles/reset.scss';
@import 'assets/styles/variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

// #root {
//   min-height: 100%;
//   background-color: #f9f9f9;
// }

:root {
  --color-secondary: #AAAABB; // Определяем переменную как CSS переменную
}

body,
html {
  height: 100%;
  /* Necessary to set to make footer stick to the bottom */
  margin: 0;
}

#root {
  display: flex; // Добавить в глобальные стили
  flex-direction: column; // Добавить в глобальные стили
  min-height: 100%; // Добавить в глобальные стили
}

a {
  color: $text-link;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Цвет трека */
}

::-webkit-scrollbar-thumb {
  background-color: #d2d5da;
  border-radius: 10px;
}