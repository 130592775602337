.mainHeader {
  background-color: #f1f1f8;
  position: fixed;
  top: 0;
  width: 100%;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
  height: 60px;
}

.fullWidth {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.headerRightSide {
  min-width: 238px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  border: 1px solid #71BE81;
  padding-left: 20px;
  background-color: #FFFFFF;
  position: relative;

  p {
    margin-right: 5px;
  }
}

.openModal {
  border: 1px solid rgba(226, 226, 237, 1);
}

.headerRightSideLeft {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}

.settingsModal {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 300px;
  background-color: #f9f9fb;
  border: 1px solid #E2E2ED;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  box-shadow: 0px 4px 4px rgba(148, 148, 148, 0.35);
}

.mobileVerisonSettingsModal {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f9f9fb;
  border: 1px solid #E2E2ED;
  // border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  // box-shadow: 0px 4px 4px rgba(148, 148, 148, 0.35);
  width: 100%;
  display: none;
}

.settingsItem {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  &.statisticsItem {
    cursor: default;
  }
}

.idText {
  color: rgba(170, 170, 187, 1);
  font-size: 11px;
  font-weight: 550;
}

/* Изначально скрываем бургер-меню на десктопной версии */
.mobileMenu {
  display: none;
  cursor: pointer;
}

.desktopMenu {
  display: flex;
}

.arrow {
  cursor: pointer;
}

.iconWrapper {
  flex-shrink: 0;
  /* Отключаем уменьшение иконки */
  width: 28px;
  /* Задаем фиксированную ширину для иконки */
  height: 28px;
  /* Задаем фиксированную высоту для иконки */
}

/* Медиазапрос для мобильных устройств */
@media (max-width: 768px) {
  .desktopMenu {
    display: none;
    /* Скрываем десктопное меню на мобильных */
  }

  .mobileVerisonSettingsModal {
    display: flex;
  }

  .mobileMenu {
    display: flex;
    /* Показываем бургер-меню */
  }

  /* Показываем модальное окно на мобильных устройствах, выравнивание по правому краю */
  .mobileSettingsModal {
    // display: block;
    position: absolute;
    top: 60px;
    right: 0;
    left: unset;
    background-color: #f9f9fb;
    border: 1px solid #E2E2ED;
    border-radius: 15px;
    width: auto;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(148, 148, 148, 0.35);
    z-index: 1000;
    margin-right: 15px;
  }
}

.headerStatisticBox {
  color: #AAAABB;
  display: flex;
  gap: 30px;
  align-items: center;

  ul {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
      cursor: pointer;
    }
  }
}

.verticalLine {
  border-left: 1px solid #AAAABB;
  height: 60px;
  margin-left: 15px;
}

.activeRoute {
  color: #71BE81;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: white;
  border-radius: 10px;
  max-width: 300px;
  max-height: 90vh;
  overflow-y: auto;
}

.modalButtons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.menuMain {
  font-size: 14px;
  font-weight: 550;
  line-height: 17px;
  text-transform: uppercase;
  color: #AAAABB;
}

.horizontalLine {
  width: 100%;
  height: 1px;
  background-color: #AAAABB;
  margin: 10px 0;
}

.activeRoute {
  color: #71BE81;
}