.submitButton {
    padding: 10px 30px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
}