.settingsItem {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.iconWrapper {
    flex-shrink: 0;
    /* Отключаем уменьшение иконки */
    width: 28px;
    /* Задаем фиксированную ширину для иконки */
    height: 28px;
    /* Задаем фиксированную высоту для иконки */
}