.tooltipContent {
    width: 100%;
    background-color: white;
    color: black;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    // max-width: 310px;
    line-height: 1.5;
    z-index: 1000;

    div {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.tooltipArrow {
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    transform: rotate(45deg) translate(10px, 0px);
}

.tooltipTrigger {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}