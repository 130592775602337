.productOption {
    position: relative;
    display: flex;
    max-width: 246px;
    width: 100%;
    padding: var(--padding-M-Large, 28px) var(--padding-M, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    background-color: #f9f9f9;
    border-radius: 15px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: border-color 0.3s ease;

    span {
        font-size: 16px;
        color: #333;
    }

    &.selected {
        border-color: black;
    }
}

.radioInput {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.bankImage {
    // width: 40px; // Устанавливаем размер изображения (можно корректировать по необходимости)
    height: auto;
    // margin-bottom: 8px; // Отступ снизу для текста
}

.offerCellImage {
    display: flex;
    width: 120px;
    height: 60px;
    margin-right: 10px;
    padding: 10.5px 0px 11.5px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #E2E2ED;
    background: #FFF;
}