.marginBottom {
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-top: 90px;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .searchInput {
    padding: 10px;
    width: 30%;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .showButton,
  .resetButton {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  .showButton {
    background-color: #4CAF50;
    color: white;
  }

  .resetButton {
    background-color: white;
    border: 1px solid #ccc;
  }
}

.filterButtons {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}

.twoFilters {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.filterMenu {
  cursor: pointer;
  margin-bottom: 16px;
}

.selectWrapper {
  width: 100%;
  margin-bottom: 16px;
}

.offerBtnMobileFilter,
.offerMobileFilter {
        width: 100%;
}

.filterButtonShow {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  padding-right: 10px;

  button {
    width: 108px;
    background-color: #71BE81 !important;
    color: white !important;
    border: none !important;
  }

}