.codeInputWrapper {
    display: flex;
    margin-top: 15px;
    gap: 8px;
}

.codeInput {
    max-width: 64px;
    width: 100%;
    height: 64px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    background-color: #F9F9FB;
    transition: all 0.2s ease;
    color: #000;

    &.error {
        border-color: #C15B5B;
        color: #C15B5B;
    }

    @media screen and (max-width: 768px) {
        height: 52px;
    }
}

.resendButton {
    color: #4CAF50;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    padding: 8px 16px;
    font-size: 14px;

    &:hover {
        color: darken(#4CAF50, 10%);
    }

    &:disabled {
        color: #ccc;
        cursor: not-allowed;
        text-decoration: none;
    }
}

.submitButton {
    height: 42px;
    margin-top: 20px;
    background-color: #71BE81;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
}

.errorMessage {
    color: #C15B5B;
    font-size: 14px;
    // margin-top: 10px;
}

.codeInputContainer {
    min-width: 512px;
    min-height: 183px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #E2E2ED;
    border-radius: 20px;

    @media (max-width: 768px) {
        width: 100%;
        min-width: unset;
    }

    label {
        font-size: 22px;
        font-weight: 500;
        // padding-bottom: 28px;
    }
}

.codeInputWithError {
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 400px;
}

.inputsContainer {
    display: flex;
    gap: 10px;
    margin: 20px 0;
}

.input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;

    &:focus {
        outline: none;
        border-color: #2196F3;
    }

    &.error {
        border-color: #C15B5B;
    }
}

.error {
    color: #C15B5B;
    margin-bottom: 10px;
    text-align: center;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.buttonsActionContainer {
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
}

.smsCodeForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
}

.verifiedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.successMessage {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #4CAF50;
    background-color: #f8fbf8;
    padding: 15px 15px;
    border-radius: 40px;
}

.successIcon {
    width: 24px;
    height: 24px;
}