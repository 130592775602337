@import "../../../assets/styles/variables.scss";

.wrapper {
  width: 100%;
  position: relative; // Обязательно для правильного позиционирования плавающего плейсхолдера
}

.content {
  position: relative;
  display: flex;
  gap: $input-icon-spacing;
  width: 100%;
}

.iconleft,
.iconright,
.passEyeButton {
  position: absolute;
  height: 24px;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
}

.passEyeButton {
  right: 8px;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.iconleft {
  left: $input-padding-left;
}

.iconleft~input {
  padding-left: calc($input-padding-left + 44px); // Учитываем ширину иконки
  box-sizing: border-box;
}

.iconright {
  right: $input-padding-right;
}

.input {
  width: 100%;
  height: 64px;
  padding-top: $input-padding-top;
  padding-bottom: $input-padding-bottom;
  padding-left: $input-padding-left;
  padding-right: $input-padding-right;
  box-sizing: border-box;
  color: $color-general; // Используем переменную для текста
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  /* 115.385% */
  border-radius: $input-border-radius;
  border: 1px solid #E2E2ED; // Используем переменную для границы
  background: $background-general; // Используем переменную для фона
  transition:
    color 0.1s,
    border 0.1s,
    background 0.1s;

  &:focus {
    border-radius: $input-border-radius;
    border: 1px solid $color-accent; // Используем переменную для границы при фокусе
    background: $color-general-2; // Используем переменную для фона при фокусе
    outline: none;

    ~.floatingLabel {
      transform: translateY(-150%);
      font-size: 11px;
      color: $color-secondary;
    }
  }

  // &:not(:focus):not(:placeholder-shown)~.floatingLabel {
  //   display: none; // Убираем лейбл при заполнении поля
  // }
}

.input_rightJoin {
  border-radius: 8px 0 0 8px;

  &:focus {
    border-radius: 8px 0 0 8px;
  }
}

.input_leftJoin {
  border-radius: 0 8px 8px 0;

  &:focus {
    border-radius: 0 8px 8px 0;
  }
}

.input.error {
  border: 1px solid $color-error; // Используем переменную для границы при ошибке
  background: $color-general-2; // Используем переменную для фона при ошибке
}

.errorMessage {
  color: $color-error; // Используем переменную для цвета текста ошибки
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  /* 136.364% */
}

.floatingLabel {
  position: absolute;
  left: calc($input-padding-left + 44px); // По умолчанию с иконкой
  top: 45%;
  transform: translateY(-30%);
  font-size: 13px;
  font-weight: 400;
  color: $color-secondary;
  pointer-events: none;
  transition:
    transform 0.2s,
    font-size 0.2s,
    color 0.2s;

  &.hideIcon {
    left: $input-padding-left;
  }

  &.filled {
    transform: translateY(-150%);
    font-size: 11px;
    color: $color-secondary;
  }
}

.errorPlaceholder {
  color: $color-error;
}

.input[type='date']~.floatingLabel {
  position: absolute;
  top: 25%; // Центрируем лейбл по вертикали
  transform: translateY(-50%);
  // left: calc($input-padding-left + 44px);
  font-size: 11px; // Стандартный размер текста для лейбла
  color: $color-secondary; // Вторичный цвет для лейбла
  pointer-events: none; // Чтобы лейбл не мешал взаимодействию с инпутом
  transition: transform 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

.input[type='date']:focus~.floatingLabel,
.input[type='date']~.floatingLabel.filled {
  // top: -10px; // Поднимаем лейбл над полем
  font-size: 11px; // Уменьшаем текст
  // color: $color-primary; // Цвет для активного состояния
}