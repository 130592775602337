.selectWrapper {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 768px) {
        max-width: unset;
    }
}